<template>
  <div class="subscribe row align-items-center justify-content-center">
    <div class="col-12 pt-0">
      <h1
        class="font-32"
        :class="$viewport.gt.md ? 'mb-5 no-wrap' : 'mb-3'"
      >
        Add a Payment Method
      </h1>
      <p class="font-17 text-gray">
        Software charges will be paid monthly, including your subscription fee, while agent services are paid as they are completed.
      </p>
      <p class="font-17 font-weight-strong">
        No payment is due today.
      </p>
      <div class="payment-method p-4 pb-5 mb-5">
        <SdBillingInfo
          :is-loading="uiFlags.isSubscribing"
          @card-created="subscribe"
        />
      </div>
    </div>
    <ElButton
      class="logout"
      size="small"
      @click="logout"
    >
      Log out
    </ElButton>
  </div>
</template>

<script>
import { ref, reactive } from '@vue/composition-api';
import SdBillingInfo from '@/components/common/form/SdBillingInfo';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { redirectToSignIn } from '@/router';

export default {
  name: 'Subscribe',
  components: { SdBillingInfo },
  setup(props, context) {
    const store = context.root.$store;

    const uiFlags = reactive({
      isSubscribing: false,
    });
    store.commit('Ui/setTestimonial', 2);
    if (store.getters['Auth/subscription']) {
      context.root.$router.push({
        name: 'app.properties',
      });
    }

    return {
      uiFlags,
      subscribe,
      logout,
    };

    async function subscribe(stripeSource) {
      uiFlags.isSubscribing = true;

      const business = store.getters['Auth/business'];

      if (!business || !business.address || !business.address.postal_code) {
        context.root.$router.replace({
          name: 'app.business-address',
          params: {
            stripeSourceId: stripeSource.id,
          },
        });

        return;
      }

      try {
        const subscribePayload = {
          stripe_source_id: stripeSource.id,
        };

        await store.dispatch('Subscription/subscribe', subscribePayload);
        await store.dispatch('Auth/me');

        context.root.$router.replace({
          name: 'app.properties',
        });
      } catch (error) {
        uiFlags.isSubscribing = false;
        showErrorNotify(context, error.message);
      }
    }

    async function logout() {
      await context.root.$store.dispatch('Auth/logout');
      redirectToSignIn(context);
    }
  },
};
</script>

<style lang="scss" scoped>
.subscribe {
  .payment-method {
    border-radius: $app-border-radius;
    -webkit-box-shadow: 0 10px 100px 4px rgba(0,0,0,0.1);
    box-shadow: 0 10px 100px 4px rgba(0,0,0,0.1);
  }
  .logout {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: $--font-size-small;
    @media (max-width: 768px) {
      top: -5rem;
      right: 0;
    }
  }
}
</style>
